import styled from 'styled-components'
import { BPSizes, Colors, Rem } from '../commons/Theme'
import { Grid } from 'commons/Grid'
import { Container } from 'commons/Container'

export const StyledPage = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: background-color 300ms ease;
`

export const StyledPageGrid = styled(Grid)`
  flex-grow: 1;
`

export const StyledPageBody = styled(Container)`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-grow: 1;
  margin-top: 40px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 80px;
  }
`

export const StyledPageTitle = styled.div`
  font-size: ${Rem(72)};
  font-weight: 600;
  color: ${Colors.grey100};

  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(80)};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(96)};
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    font-size: ${Rem(120)};
  }
`

export const StyledPageSubtitle = styled.div`
  font-size: ${Rem(24)};
  font-weight: 600;
  color: ${Colors.grey100};

  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(28)};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(32)};
  }
`

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`

export const ColumnRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 0px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    justify-content: flex-end;
  }
`

export const StyledBodyMessage = styled.div`
  color: ${Colors.grey100};
`
