import { AtomLink } from 'atoms/AtomLink'
import { Colors, StyledP } from 'commons/Theme'
import { ChatActivate } from 'components/Chat/ChatActivate'
import Header from 'components/Header/Header'
import { UserLoginProvider } from 'contexts/UserLoginContext'
import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { useSiteMenuLinks } from 'hooks/use-menu-site-links'
import { useMenuSocial } from 'hooks/use-menu-social'
import lottie from 'lottie-web'
import {
  ColumnLeft,
  ColumnRight,
  StyledPage,
  StyledPageBody,
  StyledPageGrid,
  StyledPageSubtitle,
  StyledPageTitle,
} from 'pages-style/404.style'
import { useEffect, useRef, useState } from 'react'
import errorAnimationJSON from '../../static/assets/lottie/a2a-404-error-page.json'
import { SiteMenuModel } from '../commons/Common.types'
import { processSiteMenu } from '../commons/utils'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'

const NotFoundPage = () => {
  const [backgroundColor, setBackgroundColor] = useState(Colors.azure600)

  const socialLinks = useMenuSocial()
  const {
    header: headerLinks,
    footer: footerLinks,
    headerTop: headerTopLinks,
  } = useSiteMenuLinks()
  const siteMenu: SiteMenuModel = processSiteMenu({
    header: headerLinks?.nodes || [],
    headerTop: headerTopLinks?.nodes || [],
    footer: footerLinks?.nodes || [],
  })
  const chatQuery = useCpaEntityQuery()

  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const errorAnimation = lottie.loadAnimation({
      container: animationRef.current as HTMLDivElement,
      animationData: errorAnimationJSON,
      loop: false,
      autoplay: true,
    })
    errorAnimation.addEventListener('enterFrame', () => {
      if (Math.floor(errorAnimation.currentFrame) == 23) {
        setBackgroundColor(Colors.blue800)
        errorAnimation.removeEventListener('enterFrame')
      }
    })
  }, [])

  return (
    <A2ADataContextProvider>
      <UserLoginProvider>
        <LayoutContextProvider>
          <StyledPage
            className="std std-light"
            $backgroundColor={backgroundColor}
          >
            <Header
              topLinks={siteMenu.headerTop}
              links={siteMenu?.header}
              socialLinks={socialLinks}
            />
            <StyledPageBody>
              <StyledPageGrid cols={1} desktopCols={2} rowsGap="0">
                <ColumnLeft>
                  <StyledPageTitle>Ops!</StyledPageTitle>
                  <StyledPageSubtitle>
                    Qualcosa é andato storto!
                  </StyledPageSubtitle>
                  <StyledP $color="grey100" style={{ marginBottom: '8px' }}>
                    La pagina che cercavi non è disponibile, ma per non
                    lasciarti al buio ti suggeriamo qualche link.
                  </StyledP>
                  <AtomLink
                    label="Vai alla homepage"
                    to="/"
                    styleType="ghost"
                    color="white"
                  />
                  <AtomLink
                    label="Vai all'assistenza"
                    to="/assistenza"
                    styleType="ghost"
                    color="white"
                  />
                </ColumnLeft>
                <ColumnRight>
                  <div style={{ display: 'flex' }} ref={animationRef} />
                </ColumnRight>
              </StyledPageGrid>
              <ChatActivate chatQuery={chatQuery} />
            </StyledPageBody>
          </StyledPage>
        </LayoutContextProvider>
      </UserLoginProvider>
    </A2ADataContextProvider>
  )
}

export default NotFoundPage
