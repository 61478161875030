import { BPSizes } from 'commons/Theme'
import styled, { css } from 'styled-components'

export const zIndexHeader = 999

export const StyledHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndexHeader};
  opacity: 1;
  transition:
    transform 0.1s ease-in-out,
    opacity 0.3s ease-in-out;

  &.hide-on-scroll {
    transform: translateY(-105%);
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    opacity: 0;
  }
`

export const StyledBackdrop = styled.div<{ $show?: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: ${zIndexHeader - 1};

  ${({ $show }) =>
    $show &&
    css`
      display: block;
    `};
`

export const StyledHeaderLogo = styled.div`
  line-height: 0;

  img,
  svg {
    width: auto;
    height: 32px;
    fill: #ffffff;

    @media screen and (min-width: ${BPSizes.md}px) {
      height: 48px;
    }
  }

  &.a2a-logo-magazine {
    img,
    svg {
      width: 100%;
      height: auto;

      @media screen and (min-width: ${BPSizes.sm}px) {
        width: auto;
        height: 32px;
      }

      @media screen and (min-width: ${BPSizes.md}px) {
        height: 48px;
      }

      @media screen and (min-width: ${BPSizes.lg}px) {
        height: 27px;
      }

      @media screen and (min-width: 1100px) {
        height: 36px;
      }

      @media screen and (min-width: 1180px) {
        height: 48px;
      }
    }
  }
`
